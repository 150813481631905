
import { defineComponent, inject } from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS, SETTINGS } from '../types';

export default defineComponent({
    name: 'Footer',
    components: {},
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);
        const settings = inject(SETTINGS);

        return {
            data,
            settings,

            imageSizes,
            cdnUrl,
        };
    },
});
