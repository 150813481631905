
import { defineComponent, inject } from 'vue';
import { SETTINGS } from '../types';

export default defineComponent({
    name: '',
    components: {},
    props: {},
    setup() {
        const settings = inject(SETTINGS);

        return {
            settings,
        };
    },
});
