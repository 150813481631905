import { createApp } from 'vue';
import vMeta from '@projectfive/v-meta';
import App from './App.vue';
import router from './router';
import { getIcon } from './services/icons-feather';
import defaultMetaData from './services/meta';

import 'p5-spark-css/dist/forms.css';
import 'p5-spark-css/dist/base.css';
import './assets/css/app.css';

const app = createApp(App)
    .use(vMeta, { metadata: defaultMetaData })
    .use(router);

app.config.globalProperties.$getIcon = getIcon;

app.mount('#app');
