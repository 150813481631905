import { InjectionKey, Ref, ComputedRef } from 'vue';

export interface Branche {
    name: string;
    sort: number;
    id: number;
}

interface BrancheGroup {
    id: number;
    sort: number;
    items: Branche[];
}

interface Highlight {
    id: number;
    sort: number;
    name: string;
    icon: string;
}

interface HighlightGroup {
    id: number;
    sort: number;
    items: Highlight[];
}

interface StepSliderStep {
    id: number;
    sort: number;
    name: string;
    text: string;
    image: string;
}

interface StepSliderStepGroup {
    id: number;
    sort: number;
    items: StepSliderStep[];
}

export interface Client {
    id: number;
    sort: number;
    name: string;
    logo: string;
    company_name: string;
    photo_1: string;
    photo_2: string;
    text: string;
}

interface OverviewItem {
    id: number;
    name: string;
    text: string;
    photo: string;
}

interface GmapsAddress {
    formatted: string;
    parts: {
        number: number;
        street: string;
        zip: string;
        city: string;
        country: string;
        country_code: string;
    };
    coords: {
        lat: number;
        lng: number;
    };
    place_id: string;
}

interface Settings {
    id: number;
    company_name: string;
    company_address: GmapsAddress;
    company_email: string;
    company_phone: string;
    company_coc: string;
    company_vat: string;
}

export interface RemoteTexts {
    id: number;
    sort: number;
    name: string;
    home_title: string;
    home_photo_1: string;
    home_photo_2: string;
    home_photo_3: string;
    home_button_title_1: string;
    home_button_title_2: string;
    stepslider_title: string;
    stepslider_text: string;
    overview_title: string;
    overview_text: string;
    clients_title: string;
    clients_text: string;
    contact_title: string;
    contact_text: string;
    contact_photo_1: string;
    contact_photo_2: string;
    branches: BrancheGroup[];
    highlights: HighlightGroup[];
    overview: OverviewItem[];
    stepslidersteps: StepSliderStepGroup[];
    clients: Client[];
    settings: Settings[];
}

export const REMOTE_TEXTS: InjectionKey<Ref<RemoteTexts>> = Symbol('remotetexts');
export const SETTINGS: InjectionKey<ComputedRef<Settings>> = Symbol('settings');
