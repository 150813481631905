
import {
    defineComponent, provide, computed, ref, inject, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import http from '@/services/http';
import { Meta, METADATA } from '@projectfive/v-meta';
import { init, destroy } from '@/services/animateIn';
import {
    RemoteTexts, REMOTE_TEXTS, SETTINGS,
} from './types';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default defineComponent({
    name: 'App',
    components: {
        Header,
        Footer,
        Meta,
    },
    setup() {
        const remoteTexts = ref<RemoteTexts|undefined>(undefined);
        http.get('items/texts/1')
            .then((r) => {
                if (r && r.results) {
                    remoteTexts.value = r.results;
                    setMeta(r.results);
                    setTimeout(animateIn, 1);
                }
            }).catch((err) => {
                console.debug(err);
            });

        const remoteSettings = computed(() => {
            if (remoteTexts.value && remoteTexts.value.settings && remoteTexts.value.settings[0]) {
                return remoteTexts.value.settings[0];
            }
            return undefined;
        });

        provide(REMOTE_TEXTS, remoteTexts);
        provide(SETTINGS, remoteSettings);

        const metadata = inject(METADATA);
        function setMeta(r) {
            // eslint-disable-next-line
            // @ts-ignore
            metadata.setDefault({
                metadata: {
                    titleDefault: r.settings[0].company_name,
                    title: `${r.home_title} vele branches`,
                    description: r.contact_text,
                },
            });
        }

        // Animate in
        const route = useRoute();
        let animateObserver;
        function animateIn() {
            if (animateObserver) { destroy(animateObserver); }
            setTimeout(() => {
                animateObserver = init(['animate-in']);
            }, 10);
        }
        watch(() => route.path, animateIn);

        return {
            remoteTexts,
        };
    },
});
