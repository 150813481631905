<template>
    <header class="py-xxl">
        <div class="c w-xxl flex justify-between align-center">
            <div class="mr-r">
                <h1 class="hide" v-if="settings">{{ settings.company_name }}</h1>
                <router-link to="/"><img src="../assets/img/cleankit_logo.svg" :alt="(settings ? settings.company_name : '') + ' logo'" class="logo" /></router-link>
            </div>
            <a href="#contact" class="button bg-accent -l s-down:hide">
                Demo & Contact
            </a>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { SETTINGS } from '../types';

export default defineComponent({
    name: '',
    components: {},
    props: {},
    setup() {
        const settings = inject(SETTINGS);

        return {
            settings,
        };
    },
});
</script>
<style>
    .logo {
        width: 80%;
        max-width: 24rem;
    }
</style>
