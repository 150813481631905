<template>
    <footer id="contact">
        <div class="bg-accent color-contrast py-xxl">
            <div class="l-up:py-xxl c w-xxl flex flex-wrap justify-start align-center">
                <div class="w-12 l-up:w-7 relative z-5">
                    <div class="w-12 l-up:w-10 xl:w-8">
                        <h2>{{ data.contact_title }}</h2>
                        <div
                            class="color-secondary richtext"
                            v-html="data.contact_text"
                        />
                    </div>
                </div>

                <div class="w-12 m-down:mt-xxl s:w-8 m:w-8 l-up:w-4 relative z-5">
                    <a
                        v-if="settings.company_phone"
                        :href="'tel:' + settings.company_phone"
                        class="contact__action card leading-xs mb-r bg-card hover:bg-accent color-contrast color-secondary"
                    >
                        Bel ons:<br />
                        <div class="flex justify-between align-center">
                            <div class="size-2 color-accent weight-b">{{ settings.company_phone }}</div>
                            <icon v-html="$getIcon('arrow-right-circle')" />
                        </div>
                    </a>
                    <a
                        v-if="settings.company_email"
                        :href="'mailto:' + settings.company_email"
                        class="contact__action card leading-xs bg-card hover:bg-accent color-contrast color-secondary"
                    >
                        Mail ons:<br />
                        <div class="flex justify-between align-center">
                            <div class="size-2 color-accent weight-b">{{ settings.company_email }}</div>
                            <icon v-html="$getIcon('arrow-right-circle')" />
                        </div>
                    </a>
                </div>

                <template
                        v-for="n in [1,2]"
                        :key="'image' + n"
                    >
                    <img
                        v-if="data['contact_photo_' + n]"
                        :srcset="imageSizes(cdnUrl(data['contact_photo_' + n]), [200, 400, 600])"
                        class="footer__image shadow-l z-1 xs:hide"
                        :class="'footer__image--' + n"
                    />
                </template>
            </div>
        </div>
    </footer>
    <div class="footer__credits c w-xxl flex flex-wrap pt-m pb-r s-down:size-6">
        <div class="w-12 s:w-6 m:w-4 l:w-3 xl:w-2 mb-s">
            {{ settings.company_name }}<br />
            <router-link to="Privacy">Privacy Policy</router-link>
        </div>
        <div class="w-12 s:w-6 m:w-4 l:w-3 xl:w-2 mb-s">
            {{ settings.company_address.parts.street }} {{ settings.company_address.parts.number }}<br />
            {{ settings.company_address.parts.zip }} {{ settings.company_address.parts.city }}
        </div>
        <div class="w-12 s:w-6 m:w-4 l:w-3 xl:w-2 flex-grow mb-s">
            KvK: {{ settings.company_coc }}<br />
            BTW: {{ settings.company_vat }}
        </div>
        <a href="https://projectfive.nl" rel="nofollow noreferrer" class="flex align-center siteby" title="Site by Project Five">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.1 24.6" class="w-24 h-24 color-secondary footer__siteby">
                <path d="M18.3 0A1.8 1.8 0 0120 2.3l-5.7 21a1.8 1.8 0 11-3.5-1l5.7-21A1.8 1.8 0 0118.3 0z" fill="currentColor"/>
                <path fill="currentColor" d="M15.2 3.4l-.1.2a9.4 9.4 0 01-1.9 1.2 12.8 12.8 0 01-3 1 17.7 17.7 0 01-3.5.4 27.8 27.8 0 01-4.1-.3l-.9 6a18.6 18.6 0 013.4-1.7 10.2 10.2 0 013-.4 14.5 14.5 0 013 .3 10.4 10.4 0 012.1.7l-1 3.7a6.7 6.7 0 00-.4-1.5 4.3 4.3 0 00-1.7-2 5.1 5.1 0 00-2.7-.6 8 8 0 00-1.9.2 9.5 9.5 0 00-1.8.7 17 17 0 00-2 1.3H1L2.7 1.4a12 12 0 001.9.5 29.2 29.2 0 005 .4l2.5-.1a20.3 20.3 0 002.3-.3c.5 0 .9-.2 1.3-.3zm-11.4 13a2.3 2.3 0 01.8.8 2.2 2.2 0 01-1 3.2c-.4.2-.6.4-.6.6 0 .4.4.6 1.3 1a8.8 8.8 0 002.7.3 5.8 5.8 0 003.3-.8l-.4 1.3a18.4 18.4 0 01-2.3.1 14.7 14.7 0 01-3-.3 9 9 0 01-2.5-.8 4.4 4.4 0 01-1.6-1.5 3.2 3.2 0 01-.5-1.7 2.4 2.4 0 01.7-1.8 2.4 2.4 0 011.8-.7 2.5 2.5 0 011.3.3z"/>
            </svg>
        </a>
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS, SETTINGS } from '../types';

export default defineComponent({
    name: 'Footer',
    components: {},
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);
        const settings = inject(SETTINGS);

        return {
            data,
            settings,

            imageSizes,
            cdnUrl,
        };
    },
});
</script>
