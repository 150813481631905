import feather from 'feather-icons';

export function getIcon(icon: string): string {
    const iconMatch = feather.icons[icon];
    if (iconMatch) {
        return iconMatch.toSvg();
    }
    return '';
}

export default getIcon;
