import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "catalog" */ '@/views/Home.vue'),
        meta: {
            guest: false,
        },
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: () => import(/* webpackChunkName: "article" */ '@/views/Article.vue'),
        meta: {
            guest: false,
            article: {
                endpoint: 'items/privacy/1',
            },
        },
    },
];

export default routes;
