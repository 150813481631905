function getElements(classnames: string[]) {
    return classnames.reduce((acc, cur) => {
        const elems = Array.from(document.querySelectorAll(`.${cur}`));
        if (elems) {
            acc.push(...elems);
        }
        return acc;
    }, [] as Element[]);
}

export function init(classnames: string[]): IntersectionObserver | false {
    if ('IntersectionObserver' in window) {
        const elements = getElements(classnames);
        document.documentElement.classList.add('supports-intersectionobserver');

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    entry.target.classList.add('animated-in');
                    observer.unobserve(entry.target);
                }
            });
        });

        elements.forEach((elem) => {
            observer.observe(elem);
        });

        return observer;
    }
    return false;
}

export function destroy(observer: IntersectionObserver): void {
    observer.disconnect();
}

export default init;
